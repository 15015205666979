@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");

.react-datepicker {
  background-color: white;
  border: none;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--light) !important;
  background: white !important;
  color: #e24020;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
}

.react-datepicker__month {
  color: #72767c;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 800;
  line-height: 20.83px;
  text-align: center;
  background: none;
}

.react-datepicker__month-text--keyboard-selected {
  color: #e24020;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 800;
  line-height: 20.83px;
  text-align: center;
  background: none;
}

.react-datepicker__month-text:hover {
  color: #72767c;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 800;
  line-height: 20.83px;
  text-align: center;
  background: none;
}
