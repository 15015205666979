.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar {
  width: 100%;
  max-width: 600px;
  padding: 0px 20px;
  border-radius: 10px;
  background-color: var(--bg-color);
}

.calendar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
}

.calendar .header .month {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: var(--text-color);
}

.calendar .header .btns {
  display: flex;
  gap: 10px;
}

.calendar .header .btns .btn {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  background-color: var(--primary-color);
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.calendar .header .btns .btn:hover {
  background-color: #db0933;
  transform: scale(1.05);
}

.weekdays {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.weekdays .day {
  width: calc(100% / 7 - 10px);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.days {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.days .day {
  width: calc(100% / 7 - 10px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  transition: all 0.3s;
}

.days .day:not(.next):not(.prev):hover {
  color: #000000;
  background-color: var(--primary-color);
  transform: scale(1.05);
}

.days .day.today {
  color: #000000;
  background-color: var(--primary-color);
}

.days .day.next,
.days .day.prev {
  color: #ccc;
}

.selectedToday {
  font-weight: 500;
  background-color: #D44C31;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  padding-top: 6px;
}

.selected {
  border-width: 2px;
  border-style: solid;
  border-color: #D44C31;
  font-weight: 500;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #000000;
  text-align: center;
  background-color: #fff;
}