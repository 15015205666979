body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(1, 1, 1, 0.12);
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #9d9da1;
}

::-moz-scrollbar {
  width: 8px;
}

/* Track */
::-moz-scrollbar-track {
  border-radius: 5px;
  background: rgba(1, 1, 1, 0.12);
  width: 8px;
}

/* Handle */
::-moz-scrollbar-thumb {
  border-radius: 5px;
  background: #9d9da1;
}
